import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

/**
 * Reveal.js directive.
 * @see https://alpinejs.dev/advanced/extending#custom-directives
 * usage with custom options:
 *   <div x-reveal="top">lorem ipsum</div>
 */

// eslint-disable-next-line no-unused-vars
export default (el, { expression }) => {
  ScrollTrigger.batch('[x-reveal="top"], [x-reveal="bottom"]', {
    onEnter: (batch) =>
      gsap.to(batch, {
        opacity: 1,
        y: 0,
        stagger: { each: 0.25 },
        overwrite: true,
      }),
  })

  ScrollTrigger.batch('[x-reveal="left"], [x-reveal="right"]', {
    onEnter: (batch) =>
      gsap.to(batch, {
        opacity: 1,
        x: 0,
        stagger: { each: 0.25 },
        overwrite: true,
      }),
  })

  ScrollTrigger.batch('[x-reveal="center"]', {
    onEnter: (batch) =>
      gsap.to(batch, {
        opacity: 1,
        y: 0,
        stagger: { each: 0.25 },
        overwrite: true,
      }),
  })
}
