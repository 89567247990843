import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default () => ({
  init() {
    // Get all elements with class animate-content
    const elemAnimateContent = gsap.utils.toArray(
      this.$el.querySelectorAll('.animate-content')
    )

    // Set default style for each iteam
    gsap.set(elemAnimateContent, {
      opacity: 0,
    })

    // Display each iteam with a delay
    ScrollTrigger.batch(elemAnimateContent, {
      onEnter: (batch) =>
        gsap.to(batch, {
          opacity: 1,
          stagger: { each: 0.05 },
          overwrite: true,
        }),
    })

    // Animate each item
    elemAnimateContent.forEach((el) => {
      // Get the number from the data attribute
      const number = el.getAttribute('data-number')

      ScrollTrigger.batch(el.querySelector('.animate-number'), {
        onEnter: (batch) =>
          gsap.to(batch, {
            innerText: number,
            snap: {
              innerText: 1,
            },
            duration: 2,
            stagger: { each: 0.05 },
            overwrite: true,
          }),
      })
    })
  },
})
