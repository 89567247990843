import isoCountries from '~/data/countries.json'
import loadScript from '~utils/loadScript'
import useFetch from './useFetch'

/**
 * Map methods
 * * ⚠️ use this composable to initialize mapbox with custom style and options
 */
export default function useMap() {
  return {
    /**
     * Get affiliated countries
     * * ⚠️ Retrieve list of countries abbreviations according to the ISO 3166 standard (mapbox needs it to display the map correctly)
     *
     * @param {object} geojson - Geojson data
     * @public
     * @example
     * const countriesAffiliated = this.getCountriesAffiliated(geojson)
     * console.log(`List of affiliated countries : ${countriesAffiliated}`)
     */
    getCountriesAffiliated(geojson) {
      return geojson.filter((item) => item.properties.type === 'affiliate')
    },

    /**
     * Get distributor countries
     * * ⚠️ Retrieve list of countries abbreviations according to the ISO 3166 standard (mapbox needs it to display the map correctly)
     *
     * @param {object} geojson - Geojson data
     * @public
     * @example
     * const countriesDistributor = this.getCountriesDistributor(geojson)
     * console.log(`List of distributor countries : ${countriesAffiliated}`)
     */
    getCountriesDistributor(geojson) {
      return geojson.filter((item) => item.properties.type === 'distributor')
    },

    /**
     * Get countries
     * * ⚠️ Retrieve list of countries abbreviations according to the ISO 3166 standard (mapbox needs it to display the map correctly)
     * @param {object} geojson - Geojson data
     * @public
     * @example
     * const countries = map.getCountries(geojson)
     * console.log(`List of all countries abbreviations : ${countries})
     */
    getCountries(geojson) {
      // Get an array of countries available without duplicates entries
      const countries = geojson.reduce((acc, obj) => {
        if (!acc.includes(obj.properties.country)) {
          acc.push(obj.properties.country)
        }
        return acc
      }, [])

      // Save countries abbreviation in an array
      const countriesAbbr = []
      countries.forEach((country) => {
        const { abbreviation } = isoCountries.find(
          (isoCountry) => isoCountry.name === country
        )
        countriesAbbr.push(abbreviation)
      })

      return countriesAbbr
    },

    /**
     * Get countries bounds
     * * ⚠️ Determine the bounds for all countries from geojson object
     * @param {object} geojson - Geojson data
     * @public
     * @example
     * const countriesBounds = map.getCountriesBounds(geojson)
     * mapbox.fitBounds(countriesBounds, {
     *   padding: 50,
     *   maxZoom: 4,
     *   minZoom: 1,
     *   duration: 1000,
     * })
     */
    getCountriesBounds(geojson) {
      return geojson.reduce(
        // eslint-disable-next-line no-shadow
        (bounds, feature) => bounds.extend(feature.geometry.coordinates),
        // eslint-disable-next-line no-undef
        new mapboxgl.LngLatBounds(geojson[0].lng, geojson[0].lat)
      )
    },

    /**
     * Init
     * * ⚠️ Initialize mapbox with custom style, options and data from API
     * @param {HTMLElement} el - Html element where map will be displayed
     * @public
     * @example
     * const { mapbox, geojson } = await map.init(this.$refs.container)
     */
    async init(el, options = {}) {
      // Load mapbox script
      await loadScript(
        'https://api.mapbox.com/mapbox-gl-js/v2.9.1/mapbox-gl.js'
      )

      // eslint-disable-next-line no-undef
      const currentLang = Stragen.current_lang

      // Prepare promises to fetch data
      const fetch = useFetch()
      const offices = await fetch.getData(
        `/wp-json/stragen/offices?lang=${currentLang}`
      )

      window.mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

      // Initialize map with custom style and options
      // eslint-disable-next-line no-unused-vars
      const mapbox = new window.mapboxgl.Map({
        container: el,
        style: import.meta.env.VITE_MAPBOX_STYLE,
        zoom: options?.zoom ?? 3,
        attributionControl: false,
        projection: 'mercator',
      })

      // disable map zoom when using scroll
      mapbox.scrollZoom.disable()

      // Merge objects with same state
      const geojson = offices.reduce((acc, obj) => {
        // Skip some countries
        if (['Norway', 'Sweden'].includes(obj.properties.country)) {
          return acc
        }

        // check if item exists
        const eqState = (item) =>
          item.properties.state &&
          JSON.stringify(item.properties.state) ===
            JSON.stringify(obj.properties.state)

        if (acc.some(eqState)) {
          // get index of matching object
          const index = acc.findIndex(eqState)

          // Prepare new object with merged properties
          acc[index] = {
            ...acc[index],
            properties: {
              ...acc[index].properties,
              description: `${acc[index].properties.description}<br>${obj.properties.description}`,
            },
          }
        } else {
          acc.push(obj)
        }

        return acc
      }, [])

      mapbox.on('load', () => {
        const placesAffiliated = this.getCountriesAffiliated(geojson)

        // Get only affiliated countries
        const countriesAffiliated = this.getCountriesAffiliated(offices)

        // Get only distributor countries
        const countriesDistributor = this.getCountriesDistributor(offices)

        mapbox.setCenter([11.4668541, 49.0329733])

        mapbox.addSource('places', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: placesAffiliated,
          },
        })

        mapbox.addLayer({
          id: 'country-boundaries',
          source: {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1',
          },
          'source-layer': 'country_boundaries',
          type: 'fill',
          paint: {
            'fill-color': '#35E9D3',
            'fill-opacity': 0,
          },
        })

        mapbox.addLayer({
          id: 'country-boundaries-distributor',
          source: {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1',
          },
          'source-layer': 'country_boundaries',
          type: 'fill',
          paint: {
            'fill-color': '#003ec1',
            'fill-opacity': 0,
          },
        })

        mapbox.setFilter('country-boundaries', [
          'in',
          'iso_3166_1_alpha_3',
          ...this.getCountries(countriesAffiliated),
        ])

        mapbox.setFilter('country-boundaries-distributor', [
          'in',
          'iso_3166_1_alpha_3',
          ...this.getCountries(countriesDistributor),
        ])

        mapbox.addLayer({
          id: 'places',
          type: 'circle',
          source: 'places',
          paint: {
            'circle-opacity': 0,
            'circle-color': '#0337CE',
            'circle-radius': 6,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff',
            'circle-stroke-opacity': 0,
          },
        })
      })

      return { mapbox, geojson }
    },
  }
}
