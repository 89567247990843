import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import useMap from '~composables/useMap'

export default () => ({
  disableScrollTrigger: false,

  async init() {
    const map = useMap()
    const { mapbox, geojson } = await map.init(this.$refs.container)

    // Determine the bounds for the features data
    const countriesBounds = map.getCountriesBounds(geojson)

    // Customize map style
    mapbox.on('load', () => {
      // Set mapbox center in the middle of Europe
      mapbox.setCenter([11.4668541, 49.0329733])

      // Get all list items to animate
      const items = gsap.utils.toArray(this.$el.querySelectorAll('li'))
      const totalDuration = 500
      const singleDuration = totalDuration / items.length
      const mm = gsap.matchMedia()

      // Disable scroll trigger on mobile and tablet
      mm.add('(max-width: 1023px)', () => {
        this.disableScrollTrigger = true

        mapbox.setPaintProperty('country-boundaries', 'fill-opacity', 1)
        mapbox.setPaintProperty(
          'country-boundaries-distributor',
          'fill-opacity',
          1
        )
        mapbox.setPaintProperty('places', 'circle-opacity', 1)
        mapbox.setPaintProperty('places', 'circle-stroke-opacity', 0.8)
      })

      // Enable scroll trigger on desktop
      if (!this.disableScrollTrigger) {
        mm.add('(min-width: 1024px)', () => {
          ScrollTrigger.create({
            id: 'presence',
            trigger: this.$el,
            start: '-100px top',
            end: `+=${totalDuration}`,
            pin: true,
            scrub: 0.5,
            onLeave: function (self) {
              const { start } = self
              self.scroll(start)
              self.disable()
              ScrollTrigger.refresh()
              window.scrollTo(0, start)
            },
          })

          items.forEach((elem, i) => {
            const smallTimeline = gsap.timeline()

            ScrollTrigger.create({
              trigger: this.$refs.wrapper,
              start: `top -=${singleDuration * i}`,
              end: `+=${singleDuration}`,
              animation: smallTimeline,
              toggleActions: 'play none play none',
              onEnter: () => {
                // Covering 77% of European population
                if (i === 0) {
                  mapbox.setPaintProperty(
                    'country-boundaries',
                    'fill-opacity',
                    1
                  )
                }

                // 7 European affiliates
                if (i === 1) {
                  mapbox.setPaintProperty('places', 'circle-opacity', 1)
                  mapbox.setPaintProperty(
                    'places',
                    'circle-stroke-opacity',
                    0.8
                  )
                }

                // 60 worldwide markets
                if (i === 2) {
                  mapbox.setPaintProperty(
                    'country-boundaries-distributor',
                    'fill-opacity',
                    1
                  )
                }
              },
            })

            smallTimeline.to(elem, { duration: 0.25, color: '#0f0f0f' }, 0)
          })
        })
      }
    })

    window.addEventListener('resize', () => {
      mapbox.fitBounds(countriesBounds, {
        padding: 100,
        maxZoom: 4,
        minZoom: 1,
        duration: 1000,
      })
    })
  },
})
