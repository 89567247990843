import Swup from 'swup'
import SwupScrollPlugin from '@swup/scroll-plugin'
import SwupOverlayTheme from '@swup/overlay-theme'
import SwupHeadPlugin from '@swup/head-plugin'
import SwupScriptsPlugin from '@swup/scripts-plugin'
import SwupGtmPlugin from '@swup/gtm-plugin'

import { enableScroll } from '../utils/scroll'

export default {
  defineSwup() {
    const getScrollPaddingTop = (el = document.documentElement) => {
      const value = getComputedStyle(el).getPropertyValue(
        '--scroll-padding-top'
      )
      return parseInt(value, 10)
    }

    const swup = new Swup({
      ignoreVisit: (url, { el } = {}) => {
        const urls = window.Stragen?.noTransitionLinks || []
        if (urls.includes(url)) return true
        if (el?.closest('[data-no-swup]')) return true
        return false
      },
      plugins: [
        new SwupScriptsPlugin({
          head: false,
          body: true,
        }),
        new SwupHeadPlugin({ persistAssets: true }),
        new SwupOverlayTheme({
          color: '#009fe3',
          duration: 600,
          direction: 'to-right',
        }),
        new SwupScrollPlugin({
          animateScroll: window.matchMedia('(prefers-reduced-motion: reduce)')
            .matches
            ? false
            : {
                betweenPages: false,
                samePageWithHash: true,
                samePage: true,
              },
          offset: getScrollPaddingTop,
        }),
        new SwupGtmPlugin()
      ],
    })

    // Scroll to specific element if hash is present (on same page if menu is open)
    swup.hooks.on('link:click', (e) => {
      if (!e.target) return
      if (
        window.matchMedia('(max-width: 1280px)').matches &&
        e.target.tagName.toLowerCase() === 'a'
      ) {
        enableScroll()

        // trigger click on burger to close menu
        document.querySelector('[x-ref="burger"]').click()
      }
    })

    // Scroll to specific element if hash is present (on page load)
    swup.hooks.on('content:replace', () => {
      enableScroll()

      if (window.location.hash) {
        const el = document.querySelector(window.location.hash)
        const offsetTop = el.getBoundingClientRect().top + window.scrollY

        window.scrollTo({
          top: offsetTop + getScrollPaddingTop(),
          behavior: 'smooth',
        })
      }
    })
  },
}
