import Choices from 'choices.js'

export default () => {
  // Get all select elements
  const select = document.querySelectorAll('.frm_form_fields select')

  // Set options for each instance
  const options = {
    searchEnabled: false,
    itemSelectText: '',
  }

  // Initialize each instance
  select.forEach((element) => {
    // eslint-disable-next-line no-unused-vars
    const choices = new Choices(element, options)
  })
}
