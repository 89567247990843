export default function useStorage() {
  return {
    /**
     * Set storage
     * * ⚠️ Web storage object localStorage allow to save key/value pairs in the browser.
     * @param {string} key - Key of the localStorage object
     * @param {string} value - Value of the localStorage object
     * @public
     * @example
     * const { setStorage, getStorage } = useStorage()
     * if (!getStorage('isLoaded')) setStorage('isLoaded', true)
     */

    setStorage(key, value) {
      localStorage.setItem(key, value)
    },

    /**
     * Get storage
     * * ⚠️ Web storage object localStorage allow to save key/value pairs in the browser.
     * @param {string} key - Key of the localStorage object
     * @public
     * @example
     * const { getStorage } = useStorage()
     * if (!getStorage('isLoaded')) console.log('Do something'')
     */
    getStorage(key) {
      return localStorage.getItem(key)
    },
  }
}
