import Choices from 'choices.js'
import useMap from '~composables/useMap'

export default () => ({
  async init() {
    // Get all select elements
    const select = document.querySelector('select[name="country"]')

    // Initialize select instance
    const choices = new Choices(select, {
      searchEnabled: false,
      itemSelectText: '',
    })

    const map = useMap()
    const zoom = window.matchMedia(`(min-width: ${twTheme.screens.lg})`).matches
      ? 2
      : 1
    const { mapbox } = await map.init(this.$refs.container, { zoom })

    // Customize map style
    mapbox.on('load', () => {
      // Add zoom and rotation controls to the map.
      // eslint-disable-next-line no-undef
      mapbox.addControl(new mapboxgl.NavigationControl(), 'bottom-right')

      // Enable mapbox zoom on double click
      mapbox.doubleClickZoom.enable()
      mapbox.boxZoom.enable()

      // Set mapbox paint properties
      mapbox.setPaintProperty('country-boundaries', 'fill-opacity', 1)
      mapbox.setPaintProperty(
        'country-boundaries-distributor',
        'fill-opacity',
        1
      )
      mapbox.setPaintProperty('places', 'circle-opacity', 1)
      mapbox.setPaintProperty('places', 'circle-stroke-opacity', 0.8)

      // Create a popup, but don't add it to the map yet.
      // eslint-disable-next-line no-undef
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true,
        closeOnMove: true,
      })

      // Prepare popup content and add event listener on link to scroll to associate office
      mapbox.on('click', 'places', (e) => {
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice()
        // eslint-disable-next-line prefer-const
        const {
          description,
          city,
          country,
          websiteUrl,
          websiteLabel,
          moreLabel,
          countryLabel,
        } = e.features[0].properties

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }

        // Populate the popup and set its coordinates
        // based on the feature found.

        let html = `<h2>${description}</h2><p>${[city, countryLabel]
          .filter((v) => v)
          .join(' - ')}</p>`

        if (!document.querySelector('body').classList.contains('home'))
          html += `<p class="mt-2"><a data-type="anchor" href="#${country.toLowerCase()}">${moreLabel}</a></p>`

        if (websiteUrl) {
          html += `<p class="mt-2"><a href="${websiteUrl.toLowerCase()}" target="_blank" rel="noopener noindex">${websiteLabel}</a></p>`
        }

        // Initialize popup with custom content
        popup.setLngLat(coordinates).setHTML(html).addTo(mapbox)

        // Get link from popup
        const link = document.querySelector(
          '.mapboxgl-map .mapboxgl-popup-content > a[data-type="anchor"]'
        )

        // Scroll to specific country
        link.addEventListener('click', (event) => {
          event.preventDefault()

          // Need to destroy and reinitalize choices
          // instance to restore initial list
          // eslint-disable-next-line no-undef
          choices.destroy()
          // eslint-disable-next-line no-undef
          choices.init()

          // eslint-disable-next-line no-shadow
          const countries = document.querySelectorAll('[data-country]')
          // eslint-disable-next-line no-shadow
          countries.forEach((country) => {
            country.classList.remove('hidden')
          })

          // Get office id from url hash
          const anchor = event.target.getAttribute('href').split('#')[1]

          // Get office element
          const element = document.getElementById(anchor)

          // Scroll to office
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          })
        })
      })

      // Change the cursor to a pointer when the mouse is over the places layer.
      mapbox.on('mouseenter', 'places', () => {
        mapbox.getCanvas().style.cursor = 'pointer'
      })

      // Change it back to a pointer when it leaves.
      mapbox.on('mouseleave', 'places', () => {
        mapbox.getCanvas().style.cursor = ''
      })
    })
  },

  filterCountries(event) {
    // Get value from search input
    const { value } = event.detail

    // Get all countries from DOM
    const countries = document.querySelectorAll('[data-country]')

    // Loop through countries and show/hide based on search value
    countries.forEach((country) => {
      const { country: name } = country.dataset

      if (name.toLowerCase().includes(value.toLowerCase())) {
        country.classList.remove('hidden')
        country.removeAttribute('style')
        country.removeAttribute('x-reveal')
      } else {
        country.classList.add('hidden')
      }
    })
  },
})
