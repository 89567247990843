import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'

import Glider from '~components/Glider'
import Counter from '~components/Counter'
import Typewriter from '~components/Typewriter'
import Video from '~components/Video'
import Language from '~components/Language'
import Header from '~components/Header'
import Accordion from '~components/Accordion'
import Presence from '~components/Presence'
import Form from '~components/Form'
import Job from '~components/Job'
import Products from '~components/Products'
import Values from '~components/Values'
import HomeMap from '~components/HomeMap'
import Hero from '~components/Hero'
import Dropzone from '~components/Dropzone'
import Lang from '~components/Lang'

import reveal from '~directives/reveal'

import useStorage from '~composables/useStorage'

import adminBar from '~store/adminBar'

import middleware from './middleware/animationBetweenPages'

export default () => {
  document.addEventListener('alpine:init', () => {
    // Register plugins
    Alpine.plugin(collapse)

    // Register store
    Alpine.store('adminBar', adminBar)

    // Register directives
    Alpine.directive('reveal', reveal)

    // Register components
    Alpine.data('header', Header)
    Alpine.data('glider', Glider)
    Alpine.data('counter', Counter)
    Alpine.data('typewriter', Typewriter)
    Alpine.data('video', Video)
    Alpine.data('language', Language)
    Alpine.data('accordion', Accordion)
    Alpine.data('homemap', HomeMap)
    Alpine.data('job', Job)
    Alpine.data('products', Products)
    Alpine.data('form', Form)
    Alpine.data('values', Values)
    Alpine.data('presence', Presence)
    Alpine.data('hero', Hero)
    Alpine.data('dropzone', Dropzone)
    Alpine.data('lang', Lang)
  })

  window.Alpine = Alpine
  Alpine.start()

  middleware.defineSwup()

  // Set isLoaded to true on first load
  const { setStorage, getStorage } = useStorage()
  if (!getStorage('isLoaded')) setStorage('isLoaded', true)

  if (window.dataLayer) {
    Array.from(document.querySelectorAll('[data-event]')).forEach((el) => {
      el.addEventListener('click', () => {
        const { event } = el.dataset
        window.dataLayer.push({ event })
      })
    })
  }
}
