export default function useCookie() {
  return {
    /**
     * Set cookie
     * * ⚠️ Save a cookie in the browser
     * @param {string} value - Value of the cookie
     * @public
     * @example
     * const cookie = useCookie()
     * cookie.setCookie(lang)‡
     */

    setCookie(value) {
      const date = new Date()

      document.cookie = `user_defined_language=${value}; expires=${new Date(
        date.setFullYear(date.getFullYear() + 1)
      ).toUTCString()}; samesite=lax; secure=true; path=/`
    },

    /**
     * Get cookie
     * * ⚠️ Get a cookie in the browser
     * @param {string} cookieName - Name of the cookie
     * @public
     * @example
     * const cookie = getCookie()
     * console.log(cookie)
     */

    getCookie(cookieName) {
      const cookies = document.cookie.split(';')
      const cookieLanguage = cookies.find((cookie) =>
        cookie.includes(cookieName)
      )
      const lang = cookieLanguage ? cookieLanguage.split('=')[1] : null
      return lang
    },
  }
}
