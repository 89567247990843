import Choices from 'choices.js'

export default () => ({
  init() {
    // Get all elements for language
    const select = document.querySelectorAll('select[name="language"]')

    // Set options for each instance
    const options = {
      searchEnabled: false,
      itemSelectText: '',
      classNames: {
        containerOuter: 'choices lang',
      },
    }

    // Initialize each instance
    select.forEach((element) => {
      // eslint-disable-next-line no-unused-vars
      const choices = new Choices(element, options)

      // Listener on change
      element.addEventListener(
        'change',
        // eslint-disable-next-line no-unused-vars
        (event) => {
          // Switch to selected language
          document.location = event.target.value
        },
        false
      )
    })
  },
})
