import useCookie from '~composables/useCookie.js'

export default () => ({
  open: false,

  init() {
    const cookie = useCookie()
    const userDefinedLanguage = cookie.getCookie('user_defined_language')
    // eslint-disable-next-line no-undef
    const currentLang = Stragen.current_lang

    // If user defined language cookie is not defined
    // or if it is different than current language
    // then show the dialog
    if (!userDefinedLanguage || userDefinedLanguage !== currentLang) {
      const dialog = document.getElementById('lang')
      dialog.showModal()
    }
  },

  close() {
    this.$root.close()
  },

  changeLang(lang) {
    const cookie = useCookie()
    cookie.setCookie(lang)
  },
})
