import gsap from 'gsap'
import useStorage from '~composables/useStorage'
import { disableScroll, enableScroll } from '../utils/scroll'

export default () => ({
  // Hide menu by default
  visible: false,
  // hover: false,
  adminBarOffsetTimeline: null,

  init() {
    window.addEventListener('resize', this.onResize.bind(this))

    this.onResize()
    window.onresize = () => {
      // Default state acording to each viewport
      if (window.matchMedia('(min-width: 1280px)').matches) {
        this.visible = true
      } else {
        this.visible = false
      }

      // Reset style on menu (hide if open)
      // Remove specific class to header and set default
      this.$root.classList.remove('bg-white')

      // Remove specific class to lang
      this.$refs.lang.classList.remove('[&>span]:text-blue-500')

      // Remove specific class to logo
      this.$refs.logo.classList.remove('[&>path]:fill-blue-500')

      // Remove specific classes to contact button
      this.$refs.contact.classList.remove('rounded-full')
      this.$refs.contact.classList.remove('p-[10px]')
      this.$refs.contact.classList.add('h-full')
      this.$refs.contact.classList.add('px-5')

      // Add specific class to burger
      this.$refs.burger.classList.remove('[&>svg:nth-of-type(1)]:hidden')
      this.$refs.burger.classList.add('[&>svg:nth-of-type(2)]:hidden')
    }

    // Trigger resize event
    window.dispatchEvent(new Event('resize'))

    // Start animation if first visit on website
    const { getStorage } = useStorage()
    if (!getStorage('isLoaded')) this.animateMenu()
  },

  animateMenu() {
    // Get menu items
    const itemMenu = this.$refs.menu.querySelectorAll('li')

    // Default style
    gsap.set(itemMenu, {
      opacity: 0,
      x: -50,
    })

    // Initialize animation
    gsap.to(itemMenu, {
      opacity: 1,
      x: 0,
      stagger: { each: 0.25 },
      overwrite: true,
    })
  },

  toggle() {
    if (window.matchMedia('(max-width: 1280px)').matches) {
      // Toggle scroll
      if (this.visible) {
        enableScroll()
      } else {
        disableScroll()
      }

      // Update state
      this.visible = !this.visible

      // Add specific class to header
      this.$root.classList.toggle('bg-white')

      // Add specific class to burger
      this.$refs.burger.classList.toggle('[&>svg:nth-of-type(1)]:hidden')
      this.$refs.burger.classList.toggle('[&>svg:nth-of-type(2)]:hidden')

      // Add specific class to lang
      this.$refs.lang.classList.toggle('[&>span]:text-blue-500')

      // Add specific class to logo
      this.$refs.logo.classList.toggle('[&>path]:fill-blue-500')

      // Add specific classes to contact button
      this.$refs.contact.classList.toggle('rounded-full')
      this.$refs.contact.classList.toggle('h-full')
      this.$refs.contact.classList.toggle('p-[10px]')
      this.$refs.contact.classList.toggle('px-5')
    }
  },

  openDialogLang() {
    const dialog = document.getElementById('lang')
    dialog.showModal()
  },

  handleAdminBarOffset(dynamic = false) {
    const wpAdminBar = document.getElementById('wpadminbar')
    const height = wpAdminBar.offsetHeight

    this.$store.adminBar.setAdminBarHeight(height)

    if (dynamic) {
      if (this.adminBarOffsetTimeline) {
        return
      }
      this.adminBarOffsetTimeline = gsap
        .timeline({
          scrollTrigger: {
            start: 'top top',
            end: `+=${height}`,
            scrub: true,
          },
        })
        .fromTo(
          this.$el,
          { top: height },
          {
            top: 0,
            onUpdate: () => {
              this.$store.adminBar.setAdminBarHeight(
                parseFloat(this.$el.style.top)
              )
            },
          }
        )
    } else {
      const key = 'adminBarOffsetTimeline'
      if (this[key]) {
        this[key].kill()
        this[key] = null
      }
      this.$el.style.top = `${height}px`
      this.$store.adminBar.setAdminBarHeight(height)
    }
  },

  onResize() {
    this.$store.adminBar.setHeaderHeight(
      window.matchMedia('(min-width: 1280px)').matches ? 80 : 60
    )
    if (this.$store.adminBar.hasAdminBar) {
      const { matches: dynamic } = window.matchMedia('(max-width: 600px)')
      this.handleAdminBarOffset(dynamic)
    }
  },

  destroy() {
    window.removeEventListener('resize', this.onResize)
    if (this.adminBarOffsetTimeline) {
      this.adminBarOffsetTimeline.kill()
    }
  },
})
