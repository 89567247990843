import { html, body, scroll } from './environnments'
/**
 * reset
 *
 * @param  positionX
 * @param  positionY
 * @public
 */
const reset = (positionX, positionY) => {
  if (typeof positionX !== 'undefined') {
    scroll.x = parseInt(positionX, 10)
  }

  if (typeof positionY !== 'undefined') {
    scroll.y = parseInt(positionY, 10)
  }

  window.scrollTo(scroll.x, scroll.y)
}

/**
 * disableScroll
 *
 * Lock scroll position, but retain settings for later
 *
 */
export function disableScroll() {
  const documentElementScrollLeft = html.scrollLeft
  const documentElementScrollTop = html.scrollTop

  const bodyScrollLeft = body.scrollLeft
  const bodyScrollTop = body.scrollTop

  scroll.left = window.scrollX || documentElementScrollLeft || bodyScrollLeft
  scroll.top = window.scrollY || documentElementScrollTop || bodyScrollTop

  html.style.setProperty('overflow', 'hidden')
  html.style.setProperty('height', '100%')

  // eslint-disable-next-line
  reset(scroll.left, scroll.top)
}

/**
 * enableScroll
 *
 * @param  position
 */
export function enableScroll(position) {
  let resumeScroll = true
  let currentPosition = position

  if (typeof currentPosition === 'undefined') {
    currentPosition = scroll.top
  }

  if (typeof currentPosition === 'boolean' && currentPosition === false) {
    resumeScroll = false
  }

  // unlock scroll position
  html.style.removeProperty('overflow')
  html.style.removeProperty('height')

  // resume scroll position if possible
  if (resumeScroll) {
    // eslint-disable-next-line
    reset(scroll.left, currentPosition)
  }
}

export const getPageYScroll = () =>
  window.scrollY || document.documentElement.scrollTop
