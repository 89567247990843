export default {
  hasAdminBar: false,
  adminBarDynamic: false,
  adminBarHeight: 0,
  headerHeight: 0,
  init() {
    this.hasAdminBar = document.body.classList.contains('admin-bar')
  },
  setAdminBarDynamic(value) {
    this.adminBarDynamic = value
  },
  setAdminBarHeight(value) {
    this.adminBarHeight = value
  },
  setHeaderHeight(value) {
    this.headerHeight = value
  },
}
