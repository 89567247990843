import Typewriter from 'typewriter-effect/dist/core'

export default ({ content }) => ({
  init() {
    // Create typewriter instance
    const typewriter = new Typewriter(this.$el, {
      loop: true,
      delay: 100,
      deleteSpeed: 50,
    })

    // Pause before launching the effect
    typewriter.pauseFor(500)

    // Type each content in the array
    content.forEach((item) => {
      typewriter.typeString(item).pauseFor(1000).deleteAll().pauseFor(500)
    })

    // Start typing
    typewriter.start()
  },
})
