export default () => ({
  expanded: false,

  toggle() {
    this.expanded = !this.expanded

    // Modify svg path
    this.$refs.button.classList.toggle('[&>path:nth-of-type(1)]:hidden')
  },
})
