import gsap from 'gsap'

export default () => ({
  init() {
    const figures = gsap.utils.toArray(this.$el.querySelectorAll('figure'))
    const images = gsap.utils.toArray(
      this.$el.querySelectorAll('.illustration')
    )
    const duration = 2000
    let durationStart = 0
    let toggleActions = 'play reverse play reverse'

    // Set initial style
    gsap.set([images, figures], {
      opacity: 0,
    })

    // Loop through each item
    for (let i = 0; i < figures.length; i += 1) {
      if (i > 0) durationStart += duration
      if (i === figures.length - 1) toggleActions = 'play none play reverse'

      gsap.to(images[i], {
        scrollTrigger: {
          trigger: images[i],
          toggleActions,
          start: `+=${durationStart.toString()}s`,
          end: `+=${duration.toString()}s`,
        },
        opacity: 1,
      })

      gsap.to(figures[i], {
        scrollTrigger: {
          trigger: figures[i],
          toggleActions,
          start: `+=${durationStart.toString()}s`,
          end: `+=${(duration / 2).toString()}s`,
        },
        opacity: 1,
      })
    }

    gsap.to(this.$el, {
      scrollTrigger: {
        pin: this.$el,
        start: '50% 55%',
        end: `+=${(duration * figures.length - 1).toString()}s top`,
        pinSpacing: true,
        onUpdate: (trigger) => {
          this.$refs.progress.style.height = `${trigger.progress * 100}%`
        },
      },
    })
  },
})
