export default () => ({
  showForm: false,
  apply() {
    const form = document.getElementById('apply-form')
    this.showForm = true

    // Get job title
    const job = this.$el.getAttribute('data-job') || 'Spontaneous application'

    // Get input to populate with job title
    const input = form.querySelector('input[id^="field_job_offer"]')

    if (input) input.value = job
  },
})
