import Glider from 'glider-js'

export default (slidePerView = 3.5) => ({
  glider: null,

  init() {
    document.addEventListener('glider-loaded', this.hideFFScrollBars)
    document.addEventListener('glider-refresh', this.hideFFScrollBars)

    this.glider = new Glider(this.$refs.wrapper, {
      slidesToShow: 1.15,
      slidesToScroll: 1,
      draggable: true,
      dots: false,
      arrows: {
        prev: this.$refs.prev,
        next: this.$refs.next,
      },
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: slidePerView,
            slidesToScroll: 1,
            draggable: true,
            dots: '.dots',
          },
        },
        {
          breakpoint: 610,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            draggable: true,
          },
        },
      ],
    })

    this.glider.refresh(true)
  },

  hideFFScrollBars(e) {
    const scrollbarHeight = 17 // Currently 17, may change with updates
    if (/firefox/i.test(navigator.userAgent)) {
      // We only need to appy to desktop. Firefox for mobile uses
      // a different rendering engine (WebKit)
      // if (window.innerWidth > 575) {
      e.target.parentNode.style.height = `${
        e.target.offsetHeight - scrollbarHeight
      }px`
      // }
    }
  },
})
