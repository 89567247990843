export default function loadScript(src) {
  return new Promise((resolve, reject) => {
    let shouldAppend = false
    let script = document.querySelector(`script[src="${src}"]`)
    if (!script) {
      script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = src
      shouldAppend = true
    } else if (script.hasAttribute('data-loaded')) {
      resolve(script)

      return
    }

    script.addEventListener('error', reject)
    script.addEventListener('abort', reject)
    script.addEventListener('load', () => {
      script.setAttribute('data-loaded', true)
      resolve(script)
    })

    if (shouldAppend) document.head.appendChild(script)
  })
}
