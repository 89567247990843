export default () => ({
  goToNextSection() {
    const hero = this.$root

    window.scrollTo({
      top: parseInt(hero.offsetHeight, 10),
      behavior: 'smooth',
    })
  },
})
