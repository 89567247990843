import gsap from 'gsap'
import '@lottiefiles/lottie-player'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import 'glider-js/glider.min.css'
import '~/styles/main.css'
import App from '~/scripts/App'
import setScrollbarWidth from '~/scripts/tailwind/plugins/spacing-container/setScrollbarWidth'

gsap.registerPlugin(ScrollToPlugin)
gsap.registerPlugin(ScrollTrigger)

document.addEventListener('DOMContentLoaded', async () => {
  await document.fonts.ready
  App()
})

window.addEventListener('load', () => {
  setScrollbarWidth()
})
window.addEventListener('resize', () => {
  setScrollbarWidth()
})
setScrollbarWidth()
