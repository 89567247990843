/**
 * Map methods
 * * ⚠️ Use this composable to fetch data from the API
 */
export default function useFetch() {
  return {
    /**
     * Get
     * * ⚠️ Send network request to the server and load new information whenever it’s needed.
     * @param {string} route - Route of the API
     * @public
     * @example
     * const getToken = useFetch('/wp-json/stragen/mapbox')
     * const getOffices = useFetch(`/wp-json/stragen/offices`)
     *
     * Promise.all([getToken, getOffices])
     *   .then((response) => {
     *    console.log(response.data)
     *  })
     * .catch((error) => {
     *    throw new Error('error while fetching data', error)
     * })
     */
    async getData(route) {
      // fetch data from the API
      const response = await fetch(route)
      const data = await response.json()

      return data
    },
  }
}
