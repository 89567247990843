import Choices from 'choices.js'

export default () => ({
  init() {
    // Get all elements for language
    const select = document.querySelectorAll('select[name="category"]')

    // Set options for each instance
    const options = {
      searchEnabled: false,
      removeItems: true,
      removeItemButton: true,
      itemSelectText: '',
    }

    // Initialize each instance
    select.forEach((element) => {
      // eslint-disable-next-line no-unused-vars
      const choices = new Choices(element, options)

      // Listener on change
      element.addEventListener(
        'change',
        // eslint-disable-next-line no-unused-vars
        (event) => {
          // Filter products
          this.filterProducts()
        },
        false
      )
    })
  },

  searchProducts() {
    // Get all DOM elements
    const products = document.querySelectorAll('[data-product]')

    // Get search value
    const searchValueProduct = document
      .querySelector('input[name="search"]')
      .value.toLowerCase()

    // Loop through each item
    products.forEach((product) => {
      const titleProduct = product.querySelector('h3').textContent.toLowerCase()

      // Show item if title include search value string
      if (titleProduct.includes(searchValueProduct) || !searchValueProduct) {
        product.classList.remove('hidden')
      } else {
        product.classList.add('hidden')
      }

      // Custom message and style if no items are visible
      this.warningMessage(product)
    })
  },

  filterProducts() {
    // Get all DOM elements
    const therapeuticAreas = this.$el.querySelectorAll(
      '[data-therapeutic-area]'
    )

    // Get selected value
    const therapeuticAreaSelected = document
      .querySelector('select[id="therapeutic_area"]')
      .value.toLowerCase()

    // Loop on each item
    therapeuticAreas.forEach((therapeuticArea) => {
      // Execyte only if a value is selected
      if (therapeuticAreaSelected) {
        // Hide all items by default
        therapeuticArea.classList.add('hidden')

        const therapeuticAreaValues = JSON.parse(
          therapeuticArea.getAttribute('data-therapeutic-area')
        )

        // Show selected item
        if (therapeuticAreaValues.includes(therapeuticAreaSelected)) {
          therapeuticArea.removeAttribute('style')
          therapeuticArea.removeAttribute('x-reveal')
          therapeuticArea.classList.remove('hidden')
        }
      } else {
        therapeuticArea.classList.remove('hidden')
      }
    })
  },

  warningMessage(element) {
    // Custom message and style if no items are visible
    const section = element.closest('section')
    const articlesVisible = section.querySelectorAll('article:not(.hidden)')

    if (articlesVisible.length === 0) {
      section.querySelector('[data-error-msg]').classList.remove('hidden')
      section.querySelector('[data-button]').classList.add('hidden')
    } else {
      section.querySelector('[data-error-msg]').classList.add('hidden')
      section.querySelector('[data-button]').classList.remove('hidden')
    }
  },

  closeDialog() {
    const { dialogProduct, dialogProductContent } = this.$refs

    // Close modal
    dialogProduct.removeAttribute('open')

    // Remove all items from list
    dialogProductContent.innerHTML = ''
  },

  openDialog() {
    const { dialogProduct } = this.$refs

    // Open modal
    dialogProduct.setAttribute('open', 'open')
  },

  constructDialog(items) {
    items.forEach((item) => {
      // Check if the browser supports the HTML template element
      // by checking the presence of the content attribute for the template element.
      if ('content' in document.createElement('template')) {
        // Prepare an item for the list
        const template = document.querySelector('#dialog')

        // Clone the line and we insert it in the list
        const clone = document.importNode(template.content, true)

        // Set the content of the line
        const href = clone.querySelector('a')
        href.setAttribute('href', item.file)

        const text = clone.querySelector('span')
        text.textContent = item.title

        const { dialogProductContent } = this.$refs
        dialogProductContent.appendChild(clone)
      }
    })
  },

  prepareToDownload() {
    // Get files from attribute
    const files = JSON.parse(this.$el.getAttribute('data-files'))

    // Get files length
    const { length } = files

    if (length > 1) {
      // Prepare and construct dialog
      this.constructDialog(files)

      // Open dialog
      this.openDialog()
    } else {
      const file = files[0]

      // Force download
      this.downloadFile(file)
    }
  },

  downloadFile(item) {
    // Create link element to download file
    const link = document.createElement('a')

    // Set link attributes
    link.download = item.file
    link.href = item.file

    // Force download
    link.click()

    // Remove link element
    link.remove()
  },
})
