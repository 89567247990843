export default () => ({
  init() {
    // Get the video element
    const { player, seekbar } = this.$refs

    // Listen for the timeupdate event
    player.addEventListener('timeupdate', () => {
      // Calculate the percentage of the video that has been played
      const percentage = Math.round(
        (player.currentTime / player.duration) * 100
      )

      // Update the seekbar value
      seekbar.style.width = `${percentage}%`
    })
  },
})
